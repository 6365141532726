<template>
  <div class="main-department-manager">
    <b-card title="Quản lý phòng ban">
      <b-row>
        <b-col lg="4">
          <div class="search-department-input">
            <b-form-input type="text" @keyup.enter="getListAllDepartment" v-model="modelDepartmentSearch.name" placeholder="Nhập tên phòng ban để tìm kiếm" />
          </div>
        </b-col>
        <b-col lg="4">
          <b-button @click="getListAllDepartment"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          >
            <feather-icon
              icon="SearchIcon"
              class="mr-50"
            />
            <span class="align-middle">Tìm kiếm</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <div class="table-school-block">
            <b-row>
              <b-col lg="12" style="margin: auto">
                <div class="acction-add-department mb-2">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary" @click="getFormAddNewEmployee"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">Thêm mới</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <span style="color: green">Có tổng số {{totalCountList}} bản ghi</span>
            <b-table
              responsive
              :items="listDepartment"
              :per-page="perrPage"
              :current-page="currentPage"
              :fields="fields"
            >
              <template #cell(organizationBranchName)="data">
                <span class="custom-a"><a @click="redirectSchoolblock(data.item)">{{data.value}}</a></span>
              </template>
              <template #cell(index) = "data">
                <span>{{ data.index + 1 }}</span>
              </template>
              <template #cell(hanhdong)="data">
                <b-button @click="getFormUpdateDepartment(data.item.id)" v-b-tooltip.hover.top="'Cập nhật'" variant="flat-primary" class="btn-icon"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button v-b-tooltip.hover.top="'Xóa'"
                          variant="flat-danger"
                          class="btn-icon" @click="deleteDepartment(data.item)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>
            </b-table>
            <template>
              <b-pagination class="float-right"
                v-model="currentPage"
                :per-page="perrPage"
                hide-goto-end-buttons
                :total-rows="rows"
              />
            </template>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-modal
        ref="modal-Department"
        size="sm"
        hide-header
        hide-footer
    >
      <div class="title-header-modal">
        <h4 class="card-title">{{isDepartment ? 'Cập nhật' : 'Tạo mới'}} phòng ban</h4>
      </div>
      <validation-observer ref="modelDepartment">
        <div class="body-add-department">
          <b-form-group>
            <validation-provider #default="{ errors }" name="organizationBranchName" rules="required">
              <label class="font-weight-bold font-size-label-custom" for="nameId">Tên phòng ban <span class="text-danger">(*)</span></label>
              <b-form-input id="nameId" :class="errors.length > 0 ? 'is-invalid':null" placeholder="Nhập tên phòng ban" v-model="modelDepartment.organizationBranchName"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <validation-provider #default="{ errors }" name="organizationBranchAcronym" rules="required|email">
              <label class="font-weight-bold font-size-label-custom" for="emailId">Email  <span class="text-danger">(*)</span></label>
              <b-form-input :class="errors.length > 0 ? 'is-invalid':null" id="emailId" placeholder="Nhập địa chỉ email" v-model="modelDepartment.email"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <validation-provider #default="{ errors }" name="username" rules="required|max:300">
              <label class="font-weight-bold font-size-label-custom" for="userNameId">Tên đăng nhập  <span class="text-danger">(*)</span></label>
              <b-form-input :class="errors.length > 0 ? 'is-invalid':null" @keyup="onChangeValidate(modelDepartment.organizationBranchAcronym)" id="userNameId" placeholder="Nhập tên đăng nhập" v-model="modelDepartment.organizationBranchAcronym"></b-form-input>
              <small v-if="isValidateUserName === true" class="text-danger">Tên đăng nhập chỉ chứa các kí tự cho phép gồm: chữ in hoa, chữ in thường, chữ số (a-z, A-Z, 0-9), dấu gạch dưới. </small>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
        <div class="footer-modal-department mt-2">
          <b-button v-if="!idDepartment" class="mr-1" size="sm" @click="addDepartment"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
          >
            <feather-icon icon="CheckIcon" />
            <span class="align-middle">Tạo mới</span>
          </b-button>
          <b-button v-if="idDepartment" class="mr-1" size="sm" @click="updateDepartment"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
          >
            <feather-icon icon="CheckIcon" />
            <span class="align-middle">Cập nhật</span>
          </b-button>
          <b-button size="sm" @click="closeModal"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-danger"
          >
            <feather-icon icon="XIcon" />
            <span class="align-middle">Đóng</span>
          </b-button>
        </div>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, VBTooltip, BFormInput, BButton, BTable, BModal, BFormGroup, BPagination,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, max } from '@validations'

import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BModal,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BPagination,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      listDepartment: [],
      currentPage: 1,
      perrPage: 10,
      indexListDepartment: null,
      required,
      max,
      idDepartment: null,
      isDepartment: false,
      modelDepartment: {
        organizationBranchName: '',
        organizationBranchAcronym: '',
        email: '',
      },
      isValidateUserName: false,
      totalRecordsList: 0,
      totalCountList: null,
      modelDepartmentSearch: {
        name: '',
        pageIndex: 0,
        pageSize: 0,
      },
      fields: [
        {
          key: 'index', label: 'Stt', thClass: 'custom-th-index',
        },
        {
          key: 'organizationBranchName', label: 'Tên phòng ban',
        },
        {
          key: 'hanhdong', label: 'Hành động', thClass: 'custom-th-action-department', tdClass: 'custom-td-action-department',
        },
      ],
      listPerPage: [
        3, 5, 10,
      ],
    }
  },
  created() {
    this.getListAllDepartment()
  },
  methods: {
    getListAllDepartment() {
      this.$crm.post('organization-branch-school/find-all-department-by-condition', this.modelDepartmentSearch).then(res => {
        this.listDepartment = res.data
        this.totalCountList = this.listDepartment.length
      })
    },
    getFormAddNewEmployee() {
      this.isDepartment = false
      this.isValidateUserName = false
      this.modelDepartment = {
        organizationBranchName: '',
        organizationBranchAcronym: '',
        email: '',
      }
      this.$refs['modal-Department'].show()
    },
    addDepartment() {
      if (this.modelDepartment.organizationBranchAcronym) {
        const validateUserName = /^[A-Za-z0-9_]+$/
        if (validateUserName.test(this.modelDepartment.organizationBranchAcronym) === false) {
          this.isValidateUserName = true
          return
        }
        if (validateUserName.test(this.modelDepartment.organizationBranchAcronym) === true) {
          this.isValidateUserName = false
        }
      }
      this.$refs.modelDepartment.validate().then(success => {
        if (success) {
          this.$crm.post('organization-branch-school/create-department', this.modelDepartment).then(res => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Thành công!',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Tạo mới thành công',
                timer: 1500,
              },
            })
            this.getListAllDepartment()
            this.$refs['modal-Department'].hide()
          })
        }
      })
    },
    getFormUpdateDepartment(id) {
      this.isDepartment = true
      this.isValidateUserName = false
      if (id) {
        this.idDepartment = id
        this.$crm.post(`organization-branch-school/find-department-by-id/${id}`).then(res => {
          this.modelDepartment = res.data
          this.$refs['modal-Department'].show()
        })
      }
    },
    updateDepartment() {
      if (this.modelDepartment.organizationBranchAcronym) {
        const validateUserName = /^[A-Za-z0-9_]+$/
        if (validateUserName.test(this.modelDepartment.organizationBranchAcronym) === false) {
          this.isValidateUserName = true
          return
        }
        if (validateUserName.test(this.modelDepartment.organizationBranchAcronym) === true) {
          this.isValidateUserName = false
        }
      }
      this.$refs.modelDepartment.validate().then(success => {
        if (success) {
          this.$crm.post(`organization-branch-school/update-department/${this.idDepartment}`, this.modelDepartment).then(res => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Thành công!',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Cập nhật thành công',
                timer: 1500,
              },
            })
            this.getListAllDepartment()
            this.$refs['modal-Department'].hide()
          })
        }
      })
    },
    deleteDepartment(item) {
      this.$swal({
        title: 'Xóa phòng ban',
        text: `Bạn có muốn xóa phòng ban ${item.organizationBranchName}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$crm.get(`organization-branch-school/delete-organization-branch/${item.id}`).then(res => {
            this.getListAllDepartment()
            this.$swal({
              icon: 'success',
              title: 'Xóa phòng ban!',
              text: 'Xóa phòng ban thành công',
              showConfirmButton: false,
              timer: 1500,
            })
          })
        }
      })
    },
    closeModal() {
      this.$refs['modal-Department'].hide()
    },
    redirectSchoolblock(item) {
      this.$router.push({
        name: 'department-school-block',
        query: {
          id: item.id,
          department: item.organizationBranchName,
        },
      })
    },
    onChangeValidate(value) {
      if (value) {
        const validateUsername = /^[A-Za-z0-9_]+$/
        if (validateUsername.test(value) === false) {
          this.isValidateUserName = true
        } else {
          this.isValidateUserName = false
        }
      } else {
        this.isValidateUserName = false
      }
    },
  },
  computed: {
    rows() {
      if (this.listDepartment) {
        return this.listDepartment.length
      }
    },
  },
}
</script>

<style lang="scss">
  @import '../../@core/scss/custom/department';
  @import "~ag-grid-community/dist/styles/ag-grid.scss";
  @import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>
